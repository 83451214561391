import React from 'react'
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import music from '../image/music.png'
import corporate_events from '../image/happy_hour.png'
import government_festivals from '../image/courthouse.png'
import btl_activities from '../image/check.png'
import brand_activations from '../image/checklist.png'


const Services = () => {

    const handleDragStart = (e) => e.preventDefault();

    const items = [
        <div className='flex relative flex-col items-center justify-between' onDragStart={handleDragStart} role="presentation" >
            <img src={music} className='w-16 h-16 object-contain absolute z-10' />
            <h1 className='bg-[#E6E6E6] px-8 py-6 text-center text-lg font-normal rounded-md mt-10'>Musical <br />Concerts</h1>
        </div>,
        <div className='flex relative flex-col items-center justify-between' onDragStart={handleDragStart} role="presentation" >
            <img src={corporate_events} className='w-20 h-20 object-contain absolute z-10' />
            <h1 className='bg-[#E6E6E6] px-8 py-6 text-center text-lg font-normal rounded-md mt-10'>Corporate <br />Events</h1>
        </div>,
        <div className='flex relative flex-col items-center justify-between' onDragStart={handleDragStart} role="presentation" >
            <img src={government_festivals} className='w-14 h-14 object-contain absolute z-10' />
            <h1 className='bg-[#E6E6E6] px-8 py-6 text-center text-lg font-normal rounded-md mt-10'>Government <br />Festivals</h1>
        </div>,
        <div className='flex relative flex-col items-center justify-between' onDragStart={handleDragStart} role="presentation" >
            <img src={brand_activations} className='w-14 h-14 object-contain absolute z-10' />
            <h1 className='bg-[#E6E6E6] px-8 py-6 text-center text-lg font-normal rounded-md mt-10'>Brand <br />Activations</h1>
        </div>,
        <div className='flex relative flex-col items-center justify-between' onDragStart={handleDragStart} role="presentation" >
            <img src={btl_activities} className='w-14 h-16 object-contain absolute z-10' />
            <h1 className='bg-[#E6E6E6] px-8 py-6 text-center text-lg font-normal rounded-md mt-10'>BTL <br />Activities</h1>
        </div>,
        <div className='flex relative flex-col items-center justify-between' onDragStart={handleDragStart} role="presentation" >
            <img src={btl_activities} className='w-14 h-16 object-contain absolute z-10' />
            <h1 className='bg-[#E6E6E6] px-8 py-6 text-center text-lg font-normal rounded-md mt-10'>BTL <br />Activities</h1>
        </div>
    ];

    return (
        <div className='w-full max-w-[1200px] mx-auto flex justify-center flex-col items-center'>

            <div className='title-tag'>
                <h1>Our Presene</h1>
            </div>

            <p className='text-center w-[80%] font-medium text-slate-600 -mt-4 mb-4'>
                Although we are immersed in the depths of music across genres, our cutting-edge innovative team is spread across verticals such as
            </p>

            <AliceCarousel
                responsive={{
                    0: { items: 1 },
                    400: { items: 2 },
                    650: { items: 3 },
                    768: { items: 4 },
                    940: { items: 5 }
                }}
                mouseTracking
                disableButtonsControls
                items={items} />
        </div>
    )
}

export default Services