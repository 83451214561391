import React from "react";
import Hero from "../components/Hero";
import About from "../components/About";
import Services from "../components/Services";
import Associations from "../components/Associations";
import Footer from "../components/Footer"
import { useScroll } from "../components/ScrollProvider";
import { useEffect } from "react";
import RecentShowcases from "../components/RecentShowcases";
import UpcomingConcerts from "../components/UpcomingConcerts";
import Artist from "../components/Artist";
import ContactUs from "../components/ContactUs";

const Home = () => {

    const { scrollTo } = useScroll();

    useEffect(() => {
        if (scrollTo) {
            const element = document.getElementById(scrollTo);
            if (element) {
                element.scrollIntoView({ behavior: "smooth", block: "start" });
            }
        }
    }, [scrollTo]);

    return (
        <div>
            <section id="hero">
                <RecentShowcases />
            </section>
            <section id="concerts">
                <UpcomingConcerts />
                <Hero />
            </section>
            <section id="about">
                <About />
            </section>
            <section id="presene">
                <Services />
                <Artist />
            </section>
            <section id="footer">
                <ContactUs />
                <Associations />
                <Footer />
            </section>
            <div className="w-screen p-2 text-center text-white mb-9 bg-gradient-to-t from-[#96228B] to-[#4A154F]">
                &copy; 2024 Aurora Digital Solutions. All rights reserved.
            </div>
        </div>
    );
};

export default Home;
