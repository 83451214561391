import React, { useState } from "react";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import logo from "../image/logo.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useScroll } from "./ScrollProvider";

const NavBar = () => {
    const { setScrollTo } = useScroll();

    const handleScrollTo = (section) => {
        setScrollTo(section);
    };

    const [nav, setNav] = useState(true);

    const handleNav = () => {
        setNav(!nav);
    };

    const history = useHistory();

    const handleRoute = (route) => {
        history.push(route);
    };

    return (
        <div className="sticky top-0 z-20 bg-white">
            <div className="w-full text-black flex justify-between items-center max-w-[1280px] mx-auto px-4 h-24 border-b-2">
                <img
                    className="md:w-[200px] w-[150px] h-[70px] ml-2 -mt-2 object-contain"
                    src={logo}
                />
                <ul className="hidden md:flex">
                    <li
                        onClick={() => {handleScrollTo("hero"); history.push("/")}}
                        className="p-4 cursor-pointer"
                    >
                        Recent Showcase
                    </li>
                    <li onClick={() =>{ handleScrollTo("concerts"); history.push("/")}} className="p-4 cursor-pointer">Upcoming Concerts</li>
                    <li onClick={() => {handleScrollTo("presene");history.push("/")}} className="p-4 cursor-pointer">Our Presene</li>
                    <li
                        onClick={() => handleRoute("/gallery")}
                        className="p-4 cursor-pointer"
                    >
                        Gallery
                    </li>
                    <li onClick={() => {handleScrollTo("about");history.push("/")}} className="p-4 cursor-pointer">About</li>
                    <li onClick={() => {handleScrollTo("footer");history.push("/")}} className="p-4 cursor-pointer">Contact Us</li>
                </ul>

                <div onClick={handleNav} className="block md:hidden">
                    {!nav ? (
                        <AiOutlineClose size={20} />
                    ) : (
                        <AiOutlineMenu size={20} />
                    )}
                </div>

                <div
                    className={
                        !nav
                            ? "fixed left-0 top-0 w-[60%] h-full text-black bg-gray-50 ease-in-out duration-500"
                            : "fixed left-[-100%]"
                    }
                >
                    <img
                        className="w-[150px] h-[70px] ml-6 mt-2 object-contain"
                        src={logo}
                    />
                    <ul className="p-4 uppercase">
                        <li
                            onClick={() => {
                                handleNav();
                                handleScrollTo("hero"); 
                                history.push("/")
                            }}
                            className="p-4 border-b border-gray-600"
                        >
                            Recent Showcase
                        </li>
                        <li
                            onClick={() => {handleNav();handleScrollTo("concerts"); history.push("/")}}
                            className="p-4 border-b border-gray-600"
                        >
                            Upcoming Concerts
                        </li>
                        <li
                            onClick={() => {handleNav();handleScrollTo("presene");history.push("/")}}
                            className="p-4 border-b border-gray-600"
                        >
                            Our Presene
                        </li>
                        <li
                            onClick={() => {
                                handleRoute("/gallery");
                                handleNav();
                            }}
                            className="p-4 border-b border-gray-600"
                        >
                            Gallery
                        </li>
                        <li
                            onClick={() => {handleNav();handleScrollTo("about");history.push("/")}}
                            className="p-4 border-b border-gray-600"
                        >
                            About
                        </li>
                        <li onClick={() => {handleNav();handleScrollTo("footer");history.push("/")}} className="p-4">
                            Contact Us
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default NavBar;
